import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';

export const PXTeamGrid = (function () {
	'use strict';

	let grid;

	function init() {
		grid = document.querySelectorAll('.pxblock--team-grid__items');

		if (grid.length === 0) return;

		grid.forEach((g) => {
			let gridItems = g.querySelectorAll('.item');
			let watcher = scrollMonitor.create(g, -200);
			let animation = anime({
				targets: gridItems,
				opacity: [0, 1],
				translateY: [150, 0],
				easing: 'easeOutQuad',
				duration: 350,
				autoplay: false,
				delay: anime.stagger(250),
			});

			watcher.enterViewport(() => {
				if (!g.classList.contains('in-viewport')) animation.play();

				g.classList.add('in-viewport');
			});
		});
	}

	return {
		init: init,
	};
})();
