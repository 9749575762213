export const PXCallout = (function () {
	"use strict";

	function init() {
		events();
	}

	function events() {}

	return {
		init: init,
	};
})();
