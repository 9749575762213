import ScrollMagic, { Scene } from 'scrollmagic';
import gsap from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import TextScramble from '../../src/utils/TextScramble';

ScrollMagicPluginGsap(ScrollMagic, gsap);

export const PXScrollingBanner = (function () {
	'use strict';

	function init() {

		const bodyEl = document.querySelector('body');

		if (document.querySelector('.pxblock--scrolling-banner') === null || bodyEl.classList.contains('wp-admin')) return;

		document.querySelectorAll('.pxblock--scrolling-banner').forEach((scrollingBanner) => {
			let controller = new ScrollMagic.Controller();
			let ticker = scrollingBanner.querySelector('.pxblock--scrolling-banner__ticker');
			let slidesContainer = scrollingBanner.querySelectorAll('.pxblock--scrolling-banner__slides-container');
			let slides = scrollingBanner.querySelectorAll('.pxblock--scrolling-banner__slide');
			let navButtons = scrollingBanner.querySelectorAll('.pxblock--scrolling-banner__nav > button');
			let bgTrack = scrollingBanner.querySelector('.pxblock--scrolling-banner__bg-track');
			let timeline = new gsap.timeline();
			let fx = new TextScramble(ticker);
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			let scrollingBannerYPos = scrollingBanner.getBoundingClientRect().top + scrollTop;
			let scenes = [];

			if (navButtons.length === 0) return;

			slides.forEach((slide, k) => {
				slide.dataset.scrollToPos = slide.getBoundingClientRect().top + scrollTop;
				let initialSlideTween = gsap.fromTo(
					slide,
					{ opacity: 1 },
					{
						opacity: 1,
						ease: 'none',
						// onStart: function () {
						// 	if (slide.dataset.tickerValue !== '') fx.setText(slide.dataset.tickerValue);
						// },
						onComplete: function () {
							console.log('complete first slide');
							gsap.to(bgTrack, { x: '-100%', ease: 'sine.out' });
						},
						onReverseComplete: function () {
							gsap.to(bgTrack, { x: '0%', ease: 'sine.out' });
							gsap.to(slide.querySelector('.container'), { opacity: 1, ease: 'sine.out' });

							if (slide.dataset.tickerValue !== '') fx.setText(slide.dataset.tickerValue);

							navButtons.forEach((button, idx) => {
								idx === 0 ? button.classList.add('active') : button.classList.remove('active');
							});
						},
					}
				);

				let regularTween = gsap.fromTo(
					slide,
					{ y: '100%' },
					{
						y: '0%',
						ease: 'none',
						onStart: function () {
							if (slide.dataset.tickerValue !== '') fx.setText(slide.dataset.tickerValue);

							gsap.to(slide.querySelector('.container'), { opacity: 1, ease: 'sine.out' });

							if (k === 1) {
								console.log('fading out first container');
								gsap.to(slides[0].querySelector('.container'), { opacity: 0, y: '-100%', ease: 'sine.out', delay: 0.75 });
							}

							navButtons.forEach((button, idx) => {
								idx === k ? button.classList.add('active') : button.classList.remove('active');
							});
						},
						onComplete: function () {
							let targetBgIndex = k === slides.length - 1 ? k : k + 1;

							gsap.to(bgTrack, { x: `-${targetBgIndex * 100}%`, ease: 'sine.out' });

							if (k !== slides.length - 1 && k !== 0) {
								console.log('fading out container on complete');
								gsap.to(slide.querySelector('.container'), { opacity: 0, y: '-100%', ease: 'sine.out', delay: 0.75 });
							}
						},
						onReverseComplete: function () {
							let targetBgIndex = k === 0 ? k : k - 1;
							gsap.to(bgTrack, { x: `-${targetBgIndex * 100}%`, ease: 'sine.out' });
							gsap.to(slides[k - 1].querySelector('.container'), { opacity: 1, y: 0, ease: 'sine.out' });

							if (slide.dataset.tickerValue !== '') fx.setText(slide.dataset.tickerValue);

							navButtons.forEach((button, idx) => {
								idx + 1 === k ? button.classList.add('active') : button.classList.remove('active');
							});
						},
					}
				);

				let targetTween = k === 0 ? initialSlideTween : regularTween;

				timeline.add(targetTween, 'scene' + k);
			});

			let scene = new ScrollMagic.Scene({
				triggerElement: scrollingBanner,
				triggerHook: 'onLeave',
				duration: (slides.length - 1) * 100 + '%',
			})
				.setPin(scrollingBanner)
				.setTween(timeline)
				.addTo(controller);

			let start = scene.scrollOffset();
			let end = scene.scrollOffset() + scene.duration();
			let sceneStep = (end - start) / slides.length;
			console.log('the scene starts at', start, 'and ends at', end);

			navButtons.forEach((btn, idx) => {
				btn.addEventListener('click', (ev) => {
					let scrollAmount = idx === 0 ? start : start + sceneStep * idx + sceneStep - 2;
					controller.scrollTo(scrollAmount);

					console.log('scrolling to: ', scrollAmount, ' from: ', start);
				});
			});
		});
	}

	return {
		init: init,
	};
})();
