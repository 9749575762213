export const PXImage_cta = (function() {
	'use strict';

	function init() {
		events();
	}

	function events() {}

	return {
		init: init
	};
})();
