export const PXTabs = (function () {
	'use strict';

	function init() {
		const bodyEl = document.querySelector('body');
		// Kill all this logic in the admin panel
		if (bodyEl.classList.contains('wp-admin')) return;

		var $tabs = $('.tabs');
		$tabs.each(function () {
			var $this = $(this);
			$this.find('.tabs__button-group button:first-child').addClass('active');
			$this.find('.tabs__content-group div:first-child').addClass('active');

			$this.find('button').each(function () {
				var $thisButton = $(this);
				$thisButton.on('click', function () {
					var $thisButtonData = $(this).data('tab');
					$(this).siblings().removeClass('active');
					$(this).addClass('active');
					$this.find('[data-content]').siblings().removeClass('active');
					$this.find('[data-content=' + $thisButtonData + ']').addClass('active');
				});
			});
		});
	}

	function events() {}

	return {
		init: init,
	};
})();
