import ScrollMagic from 'scrollmagic';
import anime from 'animejs';

export const PXPostListing = (function () {
	'use strict';

	var $post_filters, $post_loaders, $post_container, $load_more, urlParams, category, baseUrl;

	function init() {
		$post_filters = $('.post-filters');
		$post_loaders = $('[data-get-posts]');
		$post_container = $('.standard-post-container__posts');
		$load_more = $('.load-more');
		urlParams = new URLSearchParams(window.location.search);
		baseUrl = [location.protocol, '//', location.host, location.pathname].join('');

		category = '';
		if (urlParams) {
			category = urlParams.get('category');
		}

		if (category) {
			// disabled because bar was removed
			updateCategory(category);
			$('#post-filters-menu nav a[data-category="'+category+'"]').addClass('active').siblings().removeClass('active');
		}

		events();
		scrollMenu();

		document.querySelectorAll('.standard-post-container__posts').forEach((postContainer) => {
			let animation = anime({
				targets: postContainer.querySelectorAll('.post'),
				translateX: ['100%', 0],
				opacity: [0, 1],
				autoplay: false,
				delay: anime.stagger(150),
				easing: 'easeOutQuart',
				duration: 800,
			});

			document.addEventListener('aos:in:aos-post-listing', ({ detail }) => {
				window.setTimeout(animation.play, 500);
			});
		});

		if (document.getElementById('post-filters-trigger') !== null) {
			var controller = new ScrollMagic.Controller();
			new ScrollMagic.Scene({
				triggerElement: '#post-filters-trigger',
				triggerHook: 1,
				reverse: true,
			})
				.setClassToggle('#post-filters-menu', 'active') // add class to reveal
				.addTo(controller);
		}
	}

	function events() {

		// toggle active state
		$post_filters.find('a').on('click', function (e) {
			e.preventDefault();

			let selectedCategorySlug = $(this).attr('data-category');
			let params;

			if (selectedCategorySlug && selectedCategorySlug !== 'all') {
				params = '?category='+selectedCategorySlug;
			} else {
				params = '';
			}

			window.history.replaceState({}, "", baseUrl + params);

			$(this).addClass('active').siblings().removeClass('active');

			document.querySelector('.load-more').dataset.category = selectedCategorySlug;
		});

		$post_loaders.on('click', function (e) {
			e.preventDefault();
			var category = $(this).data('category');

			let currentPagedValue = this.dataset.paged;
			let currentExcludeValue = this.dataset.exclude;
			let targetUrl = '/wp-json/insights/v1/category/' + category + '/paged/' + currentPagedValue + '/exclude/' + currentExcludeValue;
			$load_more.data('category', category);
			$load_more.hide();

			if ($(this).data('refresh-posts') == 'clear') {
				$post_container.html('');
			}

			$.ajax({
				url: targetUrl,
				success: function (data) {
					$(data.posts)
						.filter(function (idx, el) {
							return typeof el.classList === 'undefined' ? false : el.classList.contains('post');
						})
						.each((idx, el) => {
							let a = anime({
								targets: el,
								translateX: ['100%', 0],
								opacity: [0, 1],
								autoplay: false,
								delay: idx * 150,
								easing: 'easeOutQuart',
								duration: 800,
							});

							$post_container.append(el);

							a.play();
						});

					currentPagedValue++;

					if (currentPagedValue > data.max_num_pages) {
						$load_more.fadeOut();
					} else {
						$load_more.show();
					}

					$load_more[0].dataset.paged = currentPagedValue;

					PixelsmithModules.Helpers.external_links();
				},
				dataType: 'json',
			});
		});
	}

	function updateCategory(category){
		let targetUrl = '/wp-json/insights/v1/category/' + category + '/paged/1/exclude/2869';
		let navItem = $('#post-filters-menu nav a')[0];
		let currentPagedValue = navItem.dataset.paged;
		let currentExcludeValue = navItem.dataset.exclude;

		$post_container.html('');
		$load_more.data('category', category);
		$load_more.hide();

		$.ajax({
			url: targetUrl,
			success: function (data) {
				$(data.posts)
					.filter(function (idx, el) {
						return typeof el.classList === 'undefined' ? false : el.classList.contains('post');
					})
					.each((idx, el) => {
						let a = anime({
							targets: el,
							translateX: ['100%', 0],
							opacity: [0, 1],
							autoplay: false,
							delay: idx * 150,
							easing: 'easeOutQuart',
							duration: 800,
						});

						$post_container.append(el);

						a.play();
					});

				currentPagedValue++;

				if (currentPagedValue > data.max_num_pages) {
					$load_more.fadeOut();
				} else {
					$load_more.show();
				}

				$load_more[0].dataset.paged = currentPagedValue;

				PixelsmithModules.Helpers.external_links();
			},
			dataType: 'json',
		});
	}

	function scrollMenu() {
		var lastScrollTop = 0;
		// console.log('inside scroll menu');
		$(document).scroll(function (event) {
			var scrollFromTop = $(this).scrollTop();
			if (scrollFromTop > lastScrollTop) {
				// downscroll code
				$post_filters.addClass('post-filters--appear');
			} else {
				// upscroll code
				$post_filters.removeClass('post-filters--appear');
			}
			lastScrollTop = scrollFromTop;
		});
	}

	return {
		init: init,
	};
})();
