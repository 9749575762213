import Glide from '@glidejs/glide';
import { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export const PXLayoutSecondary = (function () {
	'use strict';

	function init() {
		const bodyEl = document.querySelector('body');
		if (bodyEl.classList.contains('wp-admin')) return;
	}

	// function initGlide(glide) {
	// 	if (null === document.querySelector('.glide')) return;

	// 	var glide = new Glide('.glide');
	// 	glide.mount();
	// 	// On Load
	// 	updateGlide(glide);
	// 	// If Image Size Change
	// 	glide.on('run', function () {
	// 		updateGlide();
	// 	});
	// 	// On Window Size Change
	// 	$(window).resize(function () {
	// 		updateGlide();
	// 	});
	// }

	// function updateGlide() {
	// 	var $carousels = $('.glide');
	// 	$carousels.each(function () {
	// 		var $this = $(this);
	// 		var $currentImage = $this.find('.glide__slide-article-image');
	// 		var $arrows = $this.find('.glide__arrows');
	// 		var $bullets = $this.find('.glide__bullets');
	// 		$arrows.css('top', $currentImage.height());
	// 		$bullets.css('top', $currentImage.height());
	// 	});
	// }

	return {
		init: init,
	};
})();
