import Glide from '@glidejs/glide';
import { debounce } from 'lodash-es';

export const PXCarousel = (function () {
	'use strict';

	let sliders;

	function init() {
		if (null === document.querySelector('.glide')) return;

		sliders = document.querySelectorAll('.glide');

		sliders.forEach((sliderEl) => {
			let sliderArrow = sliderEl.querySelector('.glide__arrow');

			if (sliderEl.querySelectorAll('.glide__slide').length < 2) return;

			let glide = new Glide(sliderEl, {
				autoplay: 2500,
				type: 'carousel',
				hoverpause: true
			});

			glide.on('build.after', () => {
				sliderArrow.classList.add('animating');
				position_slider_nav(null, sliderEl);
			});

			glide.on('run', () => {
				sliderArrow.classList.remove('animating');

				// Trigger a repaint to reload the CSS animation state
				void sliderArrow.offsetWidth;

				sliderArrow.classList.add('animating');
			});

			glide.mount();

			$(sliderEl).find('.glide__arrow').on('click', function() {
				console.log('boom');
				glide.update({autoplay: 0});
			});
			// sliderEl.addEventListener('mouseover', (ev) => glide.pause());
			// sliderEl.addEventListener('mouseout', (ev) => glide.play());

			window.addEventListener(
				'resize',
				debounce(() => position_slider_nav(null, sliderEl), 100)
			);
		});
	}

	function position_slider_nav(ev, sliderEl) {
		if (null === sliderEl.querySelector('.glide__slide-article-image')) return;

		let img = sliderEl.querySelector('.glide__slide-article-image');
		let arrows = sliderEl.querySelector('.glide__arrows');
		let bullets = sliderEl.querySelector('.glide__bullets');
		let imgHeight = img.getBoundingClientRect().height;

		arrows.style.top = imgHeight + 'px';
		bullets.style.top = imgHeight + 'px';
	}

	return {
		init: init,
	};
})();
